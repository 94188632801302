<template>
  <div class="box">
    <div class="boxLeft">
      <div class="top">
        <span class="title">活动通知</span>
        <div class="pointer" @click="handleLeftMore">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right" style="color: #ffffff"></i>
        </div>
      </div>
      <div class="bottom" v-loading="leftLoading">
        <div class="font" v-for="(item, index) in leftList" :key="index">
          <span @click="goDetail(item)" class="pointer">{{
            item.title | fullTextFormat(17)
          }}</span>
        </div>
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="boxCenter">
      <div class="top">
        <el-tabs v-model="activeName" @tab-click="handleClickCenterTab">
          <el-tab-pane
            v-for="(item, index) in tags"
            :name="item.name"
            :key="index"
          >
            <span slot="label" class="titleBox">
              <img
                :src="activeName === item.name ? item.imgActive : item.img"
                alt=""
              />
              <span class="title">{{ item.label }}</span>
            </span>
          </el-tab-pane>
        </el-tabs>
        <div class="moreBox pointer" @click="handleCerterMore">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="bottom" v-loading="centerLoading">
        <div v-for="(item, index) in centerList" :key="index">
          <div v-if="index === 0" class="titleBox">
            <el-image
              :src="item.img_url ? item.img_url : defaultImg"
              fit="scale-down"
              class="img pointer"
              @click="goDetail(item)"
            ></el-image>
            <div>
              <div class="samllTitle">
                <span @click="goDetail(item)" class="pointer">{{
                  item.title | fullTextFormat(25)
                }}</span>
              </div>
               <!--<div class="smallTime tc">
                发布时间：{{ item.created_time | secondFormat("LL") }} 
              </div>-->
              <div class="smallContent">
                <span @click="goDetail(item)" class="pointer">{{
                  item.content | fullTextFormat(80)
                }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="titles">
              <div class="titleItem">
                <div class="titleText pointer" @click="goDetail(item)">
                  {{ item.title | textFormat }}
                </div>
                <div class="titleDate pointer" @click="goDetail(item)">
                  {{ item.created_time | secondFormat("LL") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <noDataImg v-if="!centerLoading && centerList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="boxRight">
      <img
        v-for="(item, index) in rightImgs"
        :src="item.img"
        :key="index"
        alt=""
        class="img pointer"
        @click="handleRightImg(item)"
      />
      <!-- <div class="moreBox">
        <span class="more" @click="goDownloadSection">最新资料</span>
        <span @click="goDownloadSection" class="pointer">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="downBox">
        <div v-for="(item) in rightList" :key="item.id" class="tieleItem">
          <span class="pointer" @click="handleRightTitle(item)">{{ item.name }}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "activity",
  data() {
    return {
      activeName: "2",
      rightImgs: [
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/applyVip.png",
          path: "/memberShip",
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/credit.png",
          path: "/creditAssessment",
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/downMajor.png",
          path: "/downloadSection",
        },
      ],
      tags: [
        {
          label: "协会动态",
          name: "2",
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/dynamic.png",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/dynamicActive.png",
        },
        {
          label: "行业信息",
          name: "3",
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/association.png",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/associationActive.png",
        },
        {
          label: "政策法规",
          name: "4",
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/policy.png",
          imgActive:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/policyActive.png",
        },
      ],
      leftList: [],
      leftLoading: false,
      centerList: [],
      centerLoading: false,
      rightList: [],
      rightLoading: false,
    };
  },
  computed: {
    defaultImg() {
      let img = "";
      switch (this.activeName) {
        case "2":
          img = "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationActivities.png";
          break;
        case "3":
          img = "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/InformationIndustry.png";
          break;
        case "4":
          img = "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/Policies_regulations.png";
          break;
      }
      return img;
    },
  },
  created() {
    this.getLeftList();
    this.getCerterList();
    this.getRightList();
  },
  methods: {
    async getLeftList() {
      let params = {
        content_type: 1,
        source: this.PJSource,
        nopage: 0,
        limit: 6,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.leftLoading = false;
      if (res.success) {
        this.leftList = res.data;
      }
    },
    async getCerterList() {
      let params = {
        content_type: this.activeName,
        source: this.PJSource,
        nopage: 0,
        limit: 5,
        start: 0,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.centerLoading = true;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      this.centerLoading = false;
      if (res.success) {
        this.centerList = res.data;
      }
    },
    handleLeftMore() {
      this.$router.push({
        path: "/activitys",
        query: { content_type: 1 },
      });
    },
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    handleClickCenterTab() {
      this.getCerterList();
    },
    handleCerterMore() {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: this.activeName * 1,
        },
      });
    },
    handleRightImg(val) {
      let path = val.path;
      if (val.path === "/memberShip" && !this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
      this.$router.push(path);
    },
    handleRightTitle(item) {
      window.open(item.url);
    },
    goDownloadSection() {
      this.$router.push("/downloadSection");
    },
    async getRightList() {
      this.rightLoading = true;
      let res = await this.$store.dispatch("API_index/downloadList", {
        source: this.PJSource,
        start: 0,
        nopage: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
        limit: 2,
      });
      this.rightLoading = false;
      if (res.success) {
        this.rightList = res.data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  height: 272px;
  .boxLeft {
    width: 302px;
    height: 100%;
    .top {
      width: 302px;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 8px 0 20px;
      justify-content: space-between;
      background: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/indexActivityLeft.png');
      background-size: 100% 100%;
      .title {
        font-size: 18px;
        font-weight: 800;
        color: #ffffff;
      }
      .more {
        font-size: 14px;
        color: #ffffff;
      }
    }
    .bottom {
      height: 226px;
      background: #f6faff;
      padding: 20px 28px 26px 20px;
      position: relative;
      .font {
        color: #1f292e;
        width: 255px;
        margin-bottom: 12px;
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
      }
    }
  }
  .boxCenter {
    width: 605px;
    height: 100%;
    .top {
      padding-top: 4px;
      width: 100%;
      height: 46px;
      overflow: hidden;
      position: relative;
      /deep/ .el-tabs__nav-wrap::after {
        background-color: #fcfcfc;
        border-bottom: 2px;
      }
      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        color: #1f292e;
      }
      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        color: #909399;
      }
      /deep/ .el-tabs__item.is-active {
        color: #1f292e;
      }
      /deep/ .el-tabs__active-bar {
        background-color: #ff5155;
      }
      .moreBox {
        position: absolute;
        right: 0;
        top: 16px;
        .more {
          font-size: 14px;
          color: #909399;
        }
      }
      .titleBox {
        padding: 0 8px;
        .titleIcon {
          color: #ff5155;
        }
        .title {
          margin-left: 14px;
        }
      }
    }
    .bottom {
      height: 226px;
      background: #fcfcfc;
      padding: 16px 20px 0px;
      position: relative;
      .titleBox {
        display: flex;
        .img {
          width: 127px;
          height: 96px;
          background: yellowgreen;
          margin-right: 20px;
        }
        .samllTitle {
          color: #1f292e;
          font-size: 16px;
          font-weight: bold;
          width: 418px;

          margin-bottom: 4px;
        }
        .smallTime {
          color: #909399;
        }
        .smallContent {
          width: 418px;
          color: #1f292e;
          font-weight: 400;
          word-wrap: break-word;
          margin-top: 10px;
        }
      }
      .titles {
        padding-top: 2px;
        .titleItem {
          display: flex;
          margin-top: 6px;
          justify-content: space-between;
          .titleText {
            color: #909399;
            max-width: 380px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .titleDate {
            color: #909399;
          }
        }
      }
    }
  }
  .boxRight {
    width: 245px;
    height: 100%;
    .img {
      width: 245px;
      height: 58px;
      margin-bottom: 10px;
    }
    .moreBox {
      display: flex;
      justify-content: space-between;
      .more {
        color: #7d8695;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .downBox {
      position: relative;
      .tieleItem {
        color: #7d8695;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 240px;
        margin-top: 4px;
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
}
</style>