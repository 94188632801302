<template>
  <div class="ecology" v-loading="loading">
    <div class="content content_area">
      <div class="title">
        打造厦货协一体化
        <span class="blue">
          生态
        </span>
      </div>
      <div class="content_bottom">
        <div class="left content-item">
          <div class="content_item_wrap">
            <div class="content_title">
              <el-image
                  style="width: 88px; height: 19px"
                  :src="img1"
                  fit="scale-down"></el-image>
            </div>
            <div class="content_item" v-for="(item,index) in leftData" :key="index" @click="goToActiveDetail(item)">
              <span>
                ·
              </span>
              {{ item.name_en | priorFormat(item.name_zh,LOCALE) }}
            </div>
            <noDataImg v-if="!loading && leftData.length === 0"></noDataImg>
          </div>
          <div class="content_more" @click="goToActive">
            了解更多活动
            <el-image style="width: 18px;height: 10px;margin-left: 10px"
                      src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home08.png"/>
          </div>
        </div>
        <div class="middle content-item">
          <div class="content_item_wrap">
            <div class="content_title">
              <el-image
                  style="width: 88px; height: 19px"
                  :src="img3"
                  fit="scale-down"></el-image>
            </div>
            <div class="content_item" v-for="(item,index) in middleData" :key="index">
              <div class="content_item-left" @click="goToPersonDetail(item)">
                <span>
                ·
              </span>
                <span>
                {{ item.name || item.name_en }}-{{ item.city_name_zh || item.city_name_en }}
              </span>
              </div>
              <div class="content_item-right" @click="goToCompany(item)">
                [{{ item.name_zh || item.name_en }}]
              </div>
            </div>
            <noDataImg v-if="!loading && middleData.length === 0"></noDataImg>
          </div>
          <div class="content_more" @click="goToPerson">
            前往人才专区
            <el-image style="width: 18px;height: 10px;margin-left: 10px"
                      src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home08.png"/>
          </div>
        </div>
        <div class="right content-item">
          <div class="content_item_wrap">
            <div class="content_title">
              <el-image
                  style="width: 88px; height: 19px"
                  :src="img2"
                  fit="scale-down"></el-image>
            </div>
            <div class="content_item" v-for="(item,index) in rightData" :key="index" @click="down(item)">
              {{ item.name|textFormat }}
            </div>
            <noDataImg v-if="!loading && rightData.length === 0"></noDataImg>
          </div>
          <div class="content_more" @click="showQc">
            前往下载专区
            <el-image style="width: 18px;height: 10px;margin-left: 10px"
                      src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home08.png"/>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="300px">
      <div>
        <el-image src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/vx.png"
                  fit="contain"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  //一体化生态
  name: "ecology",
  data() {
    return {
      loading: false,
      leftData: [],
      middleData: [],
      rightData: [],
      dialogVisible: false,
      img1: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/notice_activity.png',
      img2: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/notice_down.png',
      img3: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/notice_rc.png'
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true;
      let res = await Promise.all([
        // this.$store.dispatch("API_index/getStaticContent", {
        //   content_type: 1,
        //   source: this.PJSource,
        //   nopage: 0,
        //   limit: 5,
        //   start: 0,
        //   is_show: 1,
        //   language: this.LOCALE === "en" ? 1 : 2
        // }),
        this.$store.dispatch("API_index/association_event", {
          limit: 5,
          start:0,
          is_show:1,
          is_top:1,
          source:this.PJSource,
          user_id:this.USER_INFO.id,
          company_id:this.USER_INFO.company_id,
          language: this.LOCALE === "en" ? 1 : 2,
        }),
        this.$store.dispatch("API_position/getJobPositionList", {
          start: 0,
          limit: 5,
          is_hot: 1,
          source: this.PJSource
        }),
        this.$store.dispatch("API_index/downloadList", {
          source: this.PJSource,
          limit: 5,
          is_show: 1,
          start: 0,
          language: this.LOCALE === "en" ? 1 : 2
        })
      ])
      if (res[0] && res[0].success) {
        this.leftData = res[0].data
      }
      if (res[1] && res[1].success) {
        this.middleData = res[1].data
      }
      if (res[2] && res[2].success) {
        this.rightData = res[2].data
      }

      this.loading = false
    },
    goToActive() {
      // this.$router.push({
      //   path: "/activitys",
      //   query: {
      //     content_type: 1,
      //   },
      // });
      this.$router.push({
          path: "/activity",
      })
    },
    goToActiveDetail(item) {
      let routeUrl = this.$router.resolve({
          path: "/activityDetail",
          query:{id: item.id}
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path: "/detail",
      //   query: {id: item.id},
      // });
    },
    goToPerson() {
      this.$router.push({
        path: '/talents/recruitment'
      })
    },
    showQc() {
      this.$router.push({
        path: '/downloadSection'
      })
    },
    goToCompany(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    down(item) {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      } else {
        window.open(item.url);
      }
    },
    goToPersonDetail(item) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: item.id,
          }),
        },
      });
    }
  }
}
</script>

<style scoped lang="less">
.ecology {
  width: 100%;
  height: 565px;
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home06.png");

  .content {
    padding: 85px 0 80px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 60px;

      .blue {
        font-size: 32px;
        font-weight: bold;
        color: #0D6BB9;
        margin-left: 10px;
      }
    }

    .content_bottom {
      display: flex;
      justify-content: space-between;

      .left {
        .content_item_wrap {
          //background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_1.png");
          background: #fff;
        }
      }

      .middle {
        .content_item_wrap {
          //background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_2.png");
          background: #fff;

          .content_item {
            cursor: default !important;
            display: flex;

            div {
              flex: 1;
            }

            .content_item-left {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }

            .content_item-right {
              padding-left: 10px;
              font-size: 14px;
              font-weight: 400;
              color: #027FFF;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .right {
        .content_item_wrap {
          //background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_3.png");
          background: #fff;

          .content_item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .content-item {
        width: 387px;
        height: 293px;

        .content_item_wrap {
          padding: 29px 18px 0;
          width: 100%;
          height: 259px;
          position: relative;

          .content_title {
            font-size: 24px;
            color: #1F292E;
            line-height: 24px;
            margin-bottom: 24px;
          }

          .content_item {
            margin-bottom: 20px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 400;
            color: #1F292E;
            cursor: pointer;
          }
        }

        .content_more {
          height: 49px;
          line-height: 49px;
          font-size: 14px;
          font-weight: 400;
          color: #027FFF;
          padding-left: 30px;
          background-color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>