<template>
  <div class="recommend content_area" v-loading="loading">
    <div class="title">
      优秀会员单位推荐
    </div>
    <div class="content">
      <el-carousel indicator-position="outside" trigger="click" height="290px">
        <el-carousel-item v-for="(item,index) in dataList" :key="index">
          <div class="item" v-for="(ee,vv) in item" :key="vv" :class="ee.company_id?'cursor':''">
            <el-image
                style="width: 100%; height: 100%"
                :src="ee.logo"
                fit="scale-down"
                @click="jumpPage(ee)"/>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {splitArr} from '@/baseUtils'

export default {
  //优秀会员单位推荐
  name: "recommend",
  data() {
    return {
      loading: false,
      dataList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      let params = {}
      params.nopage = 1
      params.source = this.PJSource
      let res = await this.$store.dispatch("API_index/getRecommentCompanyList", params)
      console.log(res)
      if (res.success) {
        let data = res.data
        this.dataList = splitArr(res.data, 8)
        this.loading = false
      }
    },
    jumpPage(ee) {
      if (ee.company_id) {
        let res = this.$router.resolve({
          path: "/companyDetail",
          query: {
            parameter: this._encode({
              company_id: ee.company_id
            })
          }
        })
        window.open(res.href, "_blank")
      }
    }
  }
}
</script>

<style scoped lang="less">
.recommend {
  padding-bottom: 100px;

  .title {
    height: 33px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 43px;
  }

  .content {
    /deep/ .el-carousel__item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .item {
      width: 282px;
      height: 88px;
      background: #FFFFFF;
      box-shadow: 0px 1px 5px 0px rgba(171, 171, 171, 0.5);
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #E9E9E9;
      margin-right: 17px;
      margin-bottom: 24px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .cursor {
      cursor: pointer;
    }
  }
}
</style>