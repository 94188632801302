<template>
  <div class="box" v-loading="loading">
    <div class="titlecontext">
      厦门市国际货运代理协会
      <span>
        资讯中心
      </span>
    </div>
    <div class="content">
      <div class="left_img" @click="goExamination">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/examination.png" alt="">
      </div>
      <div class="right_img" @click="putEntrance">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/law.png" alt="">
      </div>
    </div>
    <div class="middle">
      <div class="left">
        <div class="middle-left-title">
          <span class="industry">
            行业信息
          </span>
          <span class="industry-more cursor" @click="goToList(3)">
            查看更多 》
          </span>
        </div>
        <div class="middle-left-content">
          <div class="item" v-for="(item, index) in topData" :key="index">
            <el-image class="img" :src="item.img_url || threeDefaultImg" />
            <div class="industry-title cursor" @click="goToDetail(item)">
              {{item.title|textFormat}}
            </div>
            <div class="industry-time">
              {{item.created_time|secondFormat('LL')}}
            </div>
          </div>
          <noDataImg v-if="topData.length === 0"></noDataImg>
        </div>
      </div>
      <div class="right">
        <el-image
            class="cursor"
            @click="goToForm"
          style="margin-bottom:16px;"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home01.png"
        />
        <el-image
            class="cursor"
            @click="goToList(16)"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home02.png"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="dynamic">
          <span class="dynamic-title">
            协会动态
          </span>
          <span class="dynamic-title-more cursor" @click="goToList(2)">
            查看更多 》
          </span>
        </div>
        <div class="dynamic-content">
          <div class="one" v-if="middleData.length">
            <div class="img">
              <el-image
                style="width: 100%;height: 100%"
                :src="middleData[0].img_url || middleDefaultImg"
              />
            </div>
            <div class="one-content">
              <div class="content-title cursor" @click="goToDetail(middleData[0])">
                {{ middleData[0].title | textFormat }}
              </div>
              <div class="date">
                {{ middleData[0].created_time | secondFormat("LL") }}
              </div>
            </div>
          </div>
          <div
            class="item"
            v-for="(item, index) in middleDataList"
            :key="index"
          >
            <div class="title">
              <div class="img"></div>
              <div class="text textOverflow cursor" @click="goToDetail(item)">
                {{ item.title | textFormat }}
              </div>
            </div>
            <div class="time">
              {{ item.created_time | secondFormat("LL") }}
            </div>
          </div>
          <noDataImg v-if="!loading && middleData.length === 0"></noDataImg>
        </div>
      </div>
      <div class="right">
        <div class="dynamic">
          <span class="dynamic-title">
            会员动态
          </span>
          <span class="dynamic-title-more cursor" @click="goToList(8)">
            查看更多 》
          </span>
        </div>
        <div class="dynamic-content">
          <div class="one" v-if="bottomData.length">
            <div class="img">
              <el-image
                style="width: 100%;height: 100%"
                :src="bottomData[0].img_url || middleDefaultImg"
              />
            </div>
            <div class="one-content">
              <div class="content-title cursor" @click="goToDetail(bottomData[0])">
                {{ bottomData[0].title | textFormat }}
              </div>
              <div class="date">
                {{ bottomData[0].created_time | secondFormat("LL") }}
              </div>
            </div>
          </div>
          <div
            class="item"
            v-for="(item, index) in bottomDataList"
            :key="index"
          >
            <div class="title">
              <div class="img"></div>
              <div class="text textOverflow cursor" @click="goToDetail(item)">
                {{ item.title | textFormat }}
              </div>
            </div>
            <div class="time">
              {{ item.created_time | secondFormat("LL") }}
            </div>
          </div>
          <noDataImg v-if="!loading && bottomData.length === 0"></noDataImg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //协会动态等等
  name: "dynamic",
  data() {
    return {
      loading: false,
      topData: [],
      middleData: [],
      middleDataList: [],
      bottomData: [],
      bottomDataList: [],
      threeDefaultImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active2.jpg",
      middleDefaultImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home05.png",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goExamination() {
      window.open('http://www.ciffttc.cn/')
    },
    putEntrance() {
      window.open('https://iffe.mofcom.gov.cn/corp/notice')
    },
    async getList() {
      this.loading = true;
      let res = await Promise.all([
        this.$store.dispatch("API_index/getStaticContent", {
          content_type: 3,
          start: 0,
          limit: 3,
          source: this.PJSource,
          language: this.LOCALE === "en" ? 1 : 2,
        }),
        this.$store.dispatch("API_index/getStaticContent", {
          content_type: 2,
          start: 0,
          limit: 5,
          source: this.PJSource,
          language: this.LOCALE === "en" ? 1 : 2,
        }),
        this.$store.dispatch("API_index/getStaticContent", {
          content_type: 8,
          start: 0,
          limit: 5,
          source: this.PJSource,
          language: this.LOCALE === "en" ? 1 : 2,
        }),
      ]);
      if (res[0] && res[0].success) {
        this.topData = res[0].data;
      }
      if (res[1] && res[1].success) {
        this.middleData = res[1].data;
        this.middleDataList = JSON.parse(JSON.stringify(res[1].data)).splice(1);
      }
      if (res[2] && res[2].success) {
        this.bottomData = res[2].data;
        this.bottomDataList = JSON.parse(JSON.stringify(res[2].data)).splice(1);
      }
      this.loading = false;
    },
    goToList(type) {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: type,
        },
      });
    },
    goToDetail(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    goToForm(){
      this.$router.push({
        path:'/memberShip'
      })
    },
  },
};
</script>

<style scoped lang="less">
.cursor{
  cursor: pointer;
}
.box {
  padding: 71px 0 57px;
  .titlecontext {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 51px;

    span {
      font-size: 32px;
      font-weight: bold;
      color: #0d6bb9;
      margin-left: 11px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
    .left_img,.right_img {
      width: 592px;
      height: 197px;
      cursor: pointer;
    }
  }
  .middle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;

    .left {
      width: 894px;

      .middle-left-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;

        .industry {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }

        .industry-more {
          font-size: 14px;
          font-weight: 400;
          color: #0d6bb9;
        }
      }

      .middle-left-content {
        display: flex;
        justify-content: flex-start;
        min-height: 240px;
        position: relative;

        .item {
          margin-right: 24px;
          width: 282px;

          .img {
            width: 282px;
            height: 160px;
            margin-bottom: 15px;
          }

          .industry-title {
            width: 100%;
            height: 42px;
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            margin-bottom: 12px;
          }

          .industry-time {
            width: 100%;
            height: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
          }
        }
      }
    }

    .right {
      width: 280px;
      padding-top: 32px;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      width: 587px;
    }

    .dynamic {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;

      .dynamic-title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }

      .dynamic-title-more {
        font-size: 14px;
        font-weight: 400;
        color: #0d6bb9;
      }
    }

    .dynamic-content {
      min-height: 260px;
      position: relative;
      .one {
        display: flex;
        margin-bottom: 16px;
        .img {
          width: 179px;
          height: 112px;
        }

        .one-content {
          flex: 1;
          margin-left: 17px;

          .content-title {
            height: 38px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 19px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            margin-bottom: 15px;
          }

          .date {
            height: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .title {
          max-width: 494px;
          display: flex;
          align-items: center;
          .img {
            width: 22px;
            height: 22px;
            background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home04.png");
          }

          .text {
            padding-left: 13px;
            width: 500px;
            height: 17px;
            font-size: 14px;
            font-weight: 400;
            color: #535353;
            line-height: 17px;
          }
          &:hover {
            .img {
              background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home03.png");
            }
            .text {
              color: #0d6bb9;
            }
          }
        }

        .time {
          margin-left: 15px;
          height: 12px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
