<template>
  <div class="educationTraining" v-loading="loading">
    <div class="content content_area">
      <div class="title">
        跨境电商
        <span class="viewMore" @click="goToJiao">
          查看更多 》
        </span>
      </div>
      <div class="video-wrap">
        <div class="item" v-for="(item, index) in dataList" :key="index">
          <div class="img-wrap">
            <el-image :src="item.cover_url" fit="cover" class="img"></el-image>
            <div class="mark">
              <el-image
                  class="img"
                  @click="handleContent(item)"
                  style="width: 50px; height: 50px"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_9.png"
                  fit="cover"/>
            </div>
          </div>
          <div class="contentBox">
            {{ item.name | textFormat }}
          </div>
        </div>
      </div>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from '@/baseComponents/videoPlay'

export default {
  //教育培训
  name: "educationTraining",
  components: {
    videoPlay
  },
  data() {
    return {
      loading: false,
      dataList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      let params = {
        source: this.PJSource,
        start: 0,
        limit: 3,
        home_flag: 1,
        video_category: 5
      }
      let res = await this.$store.dispatch('API_company/association_video_List', params)
      this.loading = false
      if (res.success) {
        this.dataList = res.data
      }
    },
    handleContent(item) {
      if (this.IS_LOGIN) {
        if (item.name) this.$refs.vidoePlay.startPlay(item.aliyun_id);
        this.$store.dispatch('API_company/play_count_Add',{ video_id: item.id})
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            if (item.name) this.$refs.vidoePlay.startPlay(item.aliyun_id);
          },
        });
      }
    },
    goToJiao(){
      this.$router.push('/education_index')
    }
  }
}
</script>

<style scoped lang="less">
.educationTraining {
  width: 100%;
  height: 528px;
  background: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/home07.png');
  margin-bottom: 81px;

  .content {
    padding: 74px 0 0;

    .title {
      font-size: 36px;
      font-weight: 600;
      color: #FFFFFF;
      margin-bottom: 30px;
      text-align: center;
      position: relative;
      .viewMore{
        position: absolute;
        right: 0;
        top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
    }

    .copywriting {
      width: 854px;
      margin: 0 auto 40px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }

    .video-wrap {
      display: flex;
      justify-content: flex-start;

      .item {
        width: 390px;
        height: 255px;
        margin-right: 16px;

        .img-wrap {
          width: 390px;
          height: 216px;
          margin-bottom: 16px;
          position: relative;

          .img {
            width: 100%;
            height: 100%;
          }

          .mark {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            height: 216px;
            line-height: 216px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .img {
              cursor: pointer;
            }
          }

          .mark:hover {
            background: rgba(0, 0, 0, 0.6);
          }

          .mark:hover .el-icon-video-play {
            color: #fff;
          }
        }

        .contentBox {
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          color: #FFFFFF;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>