<template>
  <div class="container">
    <carouselBanner paddingTop="24%" :bannerType="1" />
    <div class="content_area">
      <dynamic />
    </div>
    <ecology />
    <educationTraining />
    <recommend />
  </div>
</template>

<script>
import loginDialog from "~xif/components/common/loginDialog";
import carouselBanner from "@/baseComponents/carouselBanner";
import activity from "~xif/components/index/home/activity";
import schoolCooperation from "~xif/components/index/home/schoolCooperation";
import education from "~xif/components/index/home/education";
import cooperation from "~xif/components/index/home/cooperation";
import baseShippingDate from "~/baseComponents/baseShippingDate/components/search";

import dynamic from "~xif/components/index/home/dynamic";
import ecology from "~xif/components/index/home/ecology";
import educationTraining from "~xif/components/index/home/educationTraining";
import recommend from "~xif/components/index/home/recommend";
export default {
  metaInfo: {
    title: "厦门货代协会--首页",
  },
  components: {
    loginDialog,
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    cooperation,
    baseShippingDate,
    dynamic,
    ecology,
    educationTraining,
    recommend
  },
  data() {
    return {
      // advertising1: null,
      advertising2: null,
      advertising3: null,
    };
  },
  computed: {},
  created() {
    this.getAdvertisingList();
  },
  methods: {
    async getAdvertisingList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "1,2,3",
      };
      let res = await this.$store.dispatch(
        "API_index/getAdvertisingList",
        params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
          // if (item.serial_no == 1) {
          //   this.advertising1 = item
          // }
          if (item.serial_no == 2) {
            this.advertising2 = item;
          }
          if (item.serial_no == 3) {
            this.advertising3 = item;
          }
        });
      }
    },
    handleLink(link) {
      if (!link) return;
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
    handleShipSearch(val, val2) {
      if (val.date) val.date = this.$moment(val.date).unix();
      this.$router.push({
        path: "/baseShippingDate",
        query: {
          polCode: val.polCode,
          podCode: val.podCode,
          date: val.date,
          polName: val2.polName,
          podName: val2.podName,
        },
      });
    },
    advertisingData(e) {
      this.advertising1 = e;
      console.log(this.advertising1);
    },
  },
};
</script>
<style scoped lang="less">
.container {
  background: #ffffff;
  .mobilizationBanner {
    margin-top: 36px;
    width: 100%;
    padding-top: 10.83%;
    position: relative;
    .mobilizationBannerImg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;
        .bannerBtn {
          position: absolute;
          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);
          &.btnStyle1 {
            border-radius: 0px;
          }
          &.btnStyle2 {
            border-radius: 4px;
          }
          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }
  .cooperation {
    margin-top: 36px;
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/footBanner.png");
    height: 299px;
    background-size: 100% 100%;
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
